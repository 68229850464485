<template>
  <DataTable
    :value="contactsComputed"
    dataKey="id"
    responsiveLayout="scroll"
    breakpoint="991px"
    data-testid="customer-contacts"
    class="c-contact-table c-compact-datatable"
    stripedRows
  >
    <Column field="firstName" :header="t('customer.contact-list.first-name')">
      <template #body="{ data }">
        <InputText
          v-model="data.firstName"
          class="w-full"
          id="contact-first-name"
          :placeholder="t('placeholder.type', { property: t('customer.contact-list.first-name').toLowerCase() })"
          @update:modelValue="updateContacts"
        />
      </template>
    </Column>

    <Column field="lastName" :header="t('customer.contact-list.last-name')">
      <template #body="{ data }">
        <InputText
          v-model="data.lastName"
          class="w-full"
          id="contact-last-name"
          :placeholder="t('placeholder.type', { property: t('customer.contact-list.last-name').toLowerCase() })"
          @update:modelValue="updateContacts"
        />
      </template>
    </Column>

    <Column field="email" :header="t('customer.contact-list.email')">
      <template #body="{ data }">
        <ContactEmail
          v-model:email="data.email"
          @update:email="updateContacts"
          :contact="data"
          :contacts="contactsComputed"
        ></ContactEmail>
      </template>
    </Column>

    <Column field="phoneNumber" :header="t('customer.contact-list.phone-number')">
      <template #body="{ data }">
        <PhoneInput
          id="contact-phone-number"
          v-model="data.phoneNumber"
          :hideLabel="true"
          @update:modelValue="updateContacts"
        />
      </template>
    </Column>

    <Column field="isInvoiceRecipient">
      <template #header>
        <div class="field">
          {{ t("customer.contact-list.invoice-recipient") }}
          <i class="pi true-icon pi-question-circle" v-tooltip="t('customer.contact-list.invoice-tooltip')"></i>
        </div>
      </template>
      <template #body="{ data }">
        <Checkbox
          v-model="data.isInvoiceRecipient"
          id="contact-invoice-recipient"
          :binary="true"
          @update:modelValue="updateContactsInvoice(data)"
        />
      </template>
    </Column>

    <Column>
      <template #body="{ data }">
        <PrimeButton
          id="contact-delete"
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="onConfirmDelete($event, data)"
        />
      </template>
    </Column>

    <template #empty>{{ t("customer.contact-list.not-found") }}</template>
  </DataTable>
  <ConfirmPopup data-testid="delete-contact-confirm"></ConfirmPopup>
  <PrimeButton
    type="button"
    class="p-button-text p-button-sm"
    data-testid="btn-add-contact"
    @click="addContact"
    id="add-contact-button"
    @keydown="handleKeyPress"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </PrimeButton>
</template>

<script setup lang="ts">
import { computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { Contact } from "@/models/customer/Contact";

import PhoneInput from "./PhoneInput.vue";
import ContactEmail from "./ContactEmail.vue";
import cloneDeep from "lodash.clonedeep";

const props = defineProps<{
  contacts: Contact[];
}>();

const emit = defineEmits<{
  (e: "update:contacts", value: Contact[]): void;
}>();

const { t } = useI18n();
const confirm = useConfirm();

const contactsComputed = computed<Contact[]>(() => {
  return props.contacts.map((c) => cloneDeep(c));
});

const addContact = () => {
  const newContact = new Contact();
  const contacts = contactsComputed.value;
  contacts.push(newContact);
  emit("update:contacts", contacts);
};

const getContactIndex = (id: string) => {
  return contactsComputed.value.findIndex((x) => x.id === id);
};

const updateContacts = () => {
  emit("update:contacts", contactsComputed.value);
};

const updateContactsInvoice = (data: Contact) => {
  const index = getContactIndex(data.id);

  if (index > -1 && contactsComputed.value[index].isInvoiceRecipient) {
    for (let i = 0; i < contactsComputed.value.length; i++) {
      if (i === index) {
        continue;
      }
      contactsComputed.value[i].isInvoiceRecipient = false;
    }
  }

  emit("update:contacts", contactsComputed.value);
};

const deleteContact = (contact: Contact) => {
  const contacts = contactsComputed.value.filter((x) => x.id !== contact.id);
  emit("update:contacts", contacts);
  document.getElementById("add-contact-button")?.focus();
};

const onConfirmDelete = (event: Event, data: Contact) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      deleteContact(data);
    },
  });
};

const handleKeyPress = (event: KeyboardEvent) => {
  const elementBtnComit = document.getElementById("btn-commit");
  const elementBtnCancel = document.getElementById("btn-cancel");

  if (event.key === "Tab" && event.shiftKey === false) {
    if (elementBtnComit?.classList.contains("p-disabled")) {
      nextTick(() => elementBtnCancel?.focus());
    } else {
      nextTick(() => elementBtnComit?.focus());
    }
    event.preventDefault();
  }
};
</script>

<style scoped lang="scss">
.c-contact-table :deep(.p-datatable-thead > tr > th) {
  padding-left: 0 !important;
}

.c-contact-table :deep(.p-datatable-tbody > tr > td) {
  padding-left: 0 !important;
}
</style>
