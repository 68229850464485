<template>
  <!-- TODO: update FloatLabelInputNumber to use locale and suffix in Components Package -->
  <div class="c-float-input-container c-minimal-inputnumber mt-3">
    <label for="credit-limit" class="c-floating-label ml-2"> {{ t("customer.credit-limit") }} </label>

    <InputNumber
      id="credit-limit"
      v-model="creditLimitComputed"
      :maxFractionDigits="0"
      data-testid="credit-limit"
      class="w-full"
      :locale="locale"
      :suffix="suffix"
      :min="-999999999"
      :max="999999999"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const props = defineProps<{
  creditLimit: number;
}>();

const emit = defineEmits<{
  (e: "update:creditLimit", value: number): void;
}>();

const creditLimitComputed = computed<number>({
  get: () => {
    return props.creditLimit ?? 0;
  },
  set: (value) => {
    emit("update:creditLimit", value);
  },
});

const suffix = computed<string>(() => {
  return locale.value === "nb-NO" ? ",-" : "";
});
</script>
<style scoped lang="scss">
.c-float-input-container {
  position: relative;
  width: 100%;
  margin-top: 0.5rem;

  .p-inputgroup {
    border-bottom: var(--floating-input-border);
    &:focus-within {
      border-bottom: var(--floating-input-border-on-focus);
    }
  }
  &:focus-within {
    .c-floating-label {
      color: var(--floating-label-color-on-focus);
    }
  }
}

.c-floating-label {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: -12px;
  transition: 0.2s ease all;
  color: var(--floating-label-color);
  font-weight: normal;
  font-size: 0.9rem;
}

.c-minimal-inputnumber {
  display: flex;
  align-items: baseline;
  padding: 0.3rem 0.5rem;

  &:focus-within {
    label {
      color: var(--floating-label-color-on-focus);
    }
  }
}

:deep(.p-inputnumber > .p-inputtext) {
  border-radius: 0;
  text-align: right;
  padding-top: 0.3rem;
  padding-bottom: 0.1rem;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: normal;
  outline: none;
  border: none;
  border-bottom: var(--floating-input-border);
  &:focus-within {
    border-bottom: var(--floating-input-border-on-focus);
    box-shadow: none;
    border-width: 3px;
  }
}

:deep(span#credit-limit) {
  &:focus-visible {
    outline-color: transparent;
    border-bottom: var(--floating-input-border-on-focus);
  }
}
</style>
