<template>
  <div class="c-minimal-inputtext w-full px-4 -my-1">
    <label for="customer-number" id="customer-number-label">
      {{ t("customer.customer-number.label") }}
      <span class="p-input-icon-right w-full" :class="{ 'p-invalid': isCustomerNumberInUse }">
        <i v-if="isChecking && customerNumberComputed" class="pi pi-spin pi-spinner"></i>
        <i v-else-if="!isChecking && customerNumberComputed && isCustomerNumberInUse" class="pi pi-times"></i>
        <i v-else-if="!isChecking && customerNumberComputed && !isCustomerNumberInUse" class="pi pi-check"></i>
      </span>
    </label>
    <InputText
      id="customer-number"
      type="text"
      v-model="customerNumberComputed"
      data-testid="customer-number"
      class="pt-2"
      :class="{ 'p-invalid': isCustomerNumberInUse }"
      :placeholder="t('customer.customer-number.placeholder')"
      v-debounce:400="checkIfCustomerNumberIsInUse"
      @focus="selectAllOnFocus"
    />
  </div>

  <small class="p-error" v-if="val.$error" data-testid="customer-number-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useCustomer } from "@/api/customer/CustomerService";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  customerNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:customerNumber", value: string): void;
}>();

const { checkCustomerNumberUsed } = useCustomer();
const isCustomerNumberInUse = ref<boolean>(false);
const isChecking = ref<boolean>(false);

const customerNumberComputed = computed<string>({
  get: () => {
    return props.customerNumber ?? "";
  },
  set: (value) => {
    emit("update:customerNumber", value);
  },
});

const { t } = useI18n();

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const checkIfCustomerNumberIsInUse = async () => {
  if (customerNumberComputed.value === "") {
    isCustomerNumberInUse.value = false;
    return;
  }
  isChecking.value = true;
  isCustomerNumberInUse.value = await checkCustomerNumberUsed(customerNumberComputed.value);
  isChecking.value = false;
};

const rules = {
  isCustomerNumberInUse: {
    customerNumberAvailable: helpers.withMessage(
      t("validations.customer-number-already-in-use"),
      (value: boolean) => !value
    ),
  },
};

const val = useVuelidate(rules, { isCustomerNumberInUse });

defineExpose({
  focus,
});
</script>
<style scoped lang="scss">
:deep(#customer-number-label) {
  color: var(--floating-label-color);
  font-size: 0.9rem;
  letter-spacing: 0.12px;
  font-weight: 400;
}
</style>
