<template>
  <CumulusRightSidebar v-model:showSidebar="showSidebarComputed" :enableDragover="false" :sidebarWidth="260"
    ><template v-slot:header>
      <div class="c-filter-header-container">
        <i class="pi pi-sliders-h"></i>
        <h1>{{ t("customer.search.filter") }}</h1>
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center">{{ t("customer.search.filter-customer-type") }}</h4>
      <div
        v-for="(value, index) in allTypesComputed"
        :key="index"
        class="flex justify-content-between relative align-items-center p-2 border-round-md"
        :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
      >
        <div class="centered-child">
          <label class="font-light" :for="'customer-type-' + value.type.toLowerCase()">{{
            t(`customer.type.${value.type.toLowerCase()}`)
          }}</label>
        </div>
        <div class="ml-auto">
          <Checkbox
            v-model="typesComputed"
            :value="value.type"
            :inputId="'customer-type-' + value.type.toLowerCase()"
            :data-testid="'customer-type-' + value.type.toLowerCase()"
          />
        </div>
      </div>

      <h4>{{ t("customer.customer-group.customer-groups") }}</h4>

      <MultiSelect
        id="customer-groups"
        data-testid="customer-groups"
        v-model="selectedCustomerGroupsIdComputed"
        :placeholder="t('placeholder.select', { property: t('customer.search.filter-customer-group').toLowerCase() })"
        optionLabel="name"
        optionValue="id"
        :options="customerGroups"
        display="chip"
        class="w-full field"
        :filter="true"
        :loading="loading"
      />
    </template>
  </CumulusRightSidebar>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { SearchFilters } from "@/repositories/search/model/SearchFilters";
import { CumulusRightSidebar } from "@cumulus/components";
import { useI18n } from "vue-i18n";
import { CustomerType } from "@/models/customer/CustomerType";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";

const { t } = useI18n();
const props = defineProps<{
  showSidebar: boolean;
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (event: "update:showSidebar", value: boolean): void;
  (event: "update:filters", value: SearchFilters): void;
}>();

const showSidebarComputed = computed<boolean>({
  get: () => props.showSidebar,
  set: (value) => emit("update:showSidebar", value),
});

const typesComputed = computed<CustomerType[]>({
  get: () => props.filters.customerTypes,
  set: (value) => {
    const filters = { ...props.filters, customerTypes: value };
    emit("update:filters", filters);
  },
});

const allTypesComputed = computed(() => {
  const allTypes = Object.values(CustomerType);
  return allTypes.map((type, index) => {
    return {
      type: type,
      index: index,
    };
  });
});

const selectedCustomerGroupsIdComputed = computed<string[]>({
  get: () => props.filters.customerGroups,
  set: (value) => {
    const filters = { ...props.filters, customerGroups: value };
    emit("update:filters", filters);
  },
});
</script>
<style scoped lang="scss">
.c-seperator-line {
  border: 1px solid var(--seperator-line);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
h4,
label {
  font-family: var(--font-family);
}
h4 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.c-filter-header-container {
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0rem;
  background-color: #fff;
  z-index: 1;
  padding: 0.5rem;

  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.c-filter-header-container i {
  margin-right: 0.5rem;
}
</style>
