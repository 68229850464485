<template>
  <div class="mt-5">
    <FloatLabelDropdown
      id="customer-state"
      dataTestId="customer-state"
      :label="t('customer.customer-status')"
      class="pt-2"
      v-model:value="customerStateComputed"
      :options="options"
      :isFloatingLabel="isFloatingLabel"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { CustomerState } from "@/models/customer/CustomerState";

const props = defineProps<{
  customerState: CustomerState;
}>();

const emit = defineEmits<{
  (e: "update:customerState", value: string): void;
}>();

const options = ref<Record<string, string>[]>([]);

const fetchCustomerStates = () => {
  for (const state in CustomerState) {
    options.value.push({
      name: t(`common.state.${state.toString().toLowerCase()}`),
      value: state,
    });
  }
};

onMounted(fetchCustomerStates);

const customerStateComputed = computed<string>({
  get: () => {
    return props.customerState ?? CustomerState.Active;
  },
  set: (value) => {
    emit("update:customerState", value);
  },
});

const { t } = useI18n();

const isFloatingLabel = computed(() => {
  return customerStateComputed.value !== "";
});
</script>
