import { httpClient, AuthHeaders } from "@cumulus/http";
import { CustomerSearchRequest } from "./model/CustomerSearchRequest";
import { CustomerSearchResponse } from "../search/model/CustomerSearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != undefined && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async customerSearch(
    authHeaders: AuthHeaders,
    request: CustomerSearchRequest
  ): Promise<CustomerSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/customers/search", request)
      .then(({ data }) => data);
  }
}
const searchApi = new SearchApi();
export { searchApi };
