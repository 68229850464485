<template>
  <FloatLabel
    id="organisation-number"
    type="text"
    v-model:value="organizationNumberComputed"
    data-testid="organisation-number"
    class="inputfield w-full pt-2"
    :placeholder="t('placeholder.type', { property: t('customer.organisation-number').toLowerCase() })"
    maxlength="8000"
    :label="t('customer.organisation-number')"
    :selectAllOnFocus="true"
    :isRequired="true"
    :isInvalid="val.$error"
  />
  <small class="p-error ml-4" v-if="val.$error" data-testid="organisation-number-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { WritableComputedRef, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  organizationNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:organizationNumber", value: string): void;
}>();

const organizationNumberComputed: WritableComputedRef<string> = computed({
  get: () => {
    return props.organizationNumber ?? "";
  },
  set: (value) => {
    emit("update:organizationNumber", value);
  },
});

const { t } = useI18n();

const rules = {
  organizationNumber: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
@/locales/i18n-validators
