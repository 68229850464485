<template>
  <CustomerToolbar
    @onToggleFilterSidebar="onToggleFilterSidebar"
    :showSidebar="showSidebar"
    v-model:filters="filtersComputed"
    :customerGroups="customerGroupStore.customerGroups"
  />
  <div class="c-customer-search-result">
    <div class="flex">
      <div class="flex-auto c-transition">
        <div class="c-card">
          <Suspense>
            <CustomerList
              :customers="customers"
              :loading="loading"
              :totalHits="totalHits"
              :page="page"
              :pageSize="pageSize"
              :sortField="sortField"
              :sortOrder="sortOrder"
              @update:sortField="onUpdateSortField"
              @update:sortOrder="onUpdateSortOrder"
              @update:page="onUpdatePage"
              @update:pageSize="onUpdatePageSize"
              @customerRefresh="onRefreshList"
            />
          </Suspense>
        </div>
      </div>
      <div class="c-transition pb-3" :class="showSidebar ? 'pl-5' : ''">
        <CustomerFilterSidebar
          :showSidebar="showSidebar"
          v-model:filters="filtersComputed"
          :loading="loading"
          :customerGroups="customerGroupStore.customerGroups"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { CustomerSearchRequest } from "@/repositories/search/model/CustomerSearchRequest";
import { SearchFilters } from "@/repositories/search/model/SearchFilters";
import { ListCustomer } from "@/repositories/search/model/ListCustomer";
import { useCustomerGroupStore } from "@/repositories/customer-group/CustomerGroupStore";
import { useSearch } from "@/repositories/search/SearchService";
import CustomerFilterSidebar from "../components/search/CustomerFilterSidebar.vue";
import CustomerToolbar from "../components/search/CustomerToolbar.vue";
import { SearchCustomer } from "@/repositories/search/model/SearchCustomer";
import CustomerList from "@/components/CustomerList.vue";

const customerGroupStore = useCustomerGroupStore();
const { customerSearch } = useSearch();
const route = useRoute();

const pageSize = ref<number>(50);
const page = ref(1);
const totalHits = ref(0);
const query = ref("");
const loading = ref(false);
const customers = ref<ListCustomer[]>([]);
const filters = ref<SearchFilters>(new SearchFilters());
const showSidebar = ref(true);
const sortOrder = ref(-1);
const sortField = ref("");

const filtersComputed = computed<SearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: SearchFilters) => {
  filters.value = value;
  loading.value = true;
  page.value = 1;
  await search();
};

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = decodeURIComponent((newQuery as string) ?? "");
      await search();
    }
  }
);

onMounted(async () => {
  query.value = decodeURIComponent((route.query.search as string) ?? "");
  await customerGroupStore.fetchCustomerGroups();
  await search();
});

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const search = async () => {
  try {
    const request = new CustomerSearchRequest(query.value);
    request.page = page.value;
    request.pageSize = pageSize.value;
    request.filters = filters.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    const response = await customerSearch(request);
    if (response.customers.length === 0) {
      customers.value = [];
      totalHits.value = 0;
      return;
    }

    customers.value = response.customers.map((c: SearchCustomer) => {
      return new ListCustomer(c, customerGroupStore.customerGroups);
    });
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};
</script>

<style scoped lang="scss">
.c-customer-search-result {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
.c-transition {
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  max-height: 90vh;
}
</style>
