{
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "minLength": "Minimum length is {min}",
    "exists": "{property} already exists",
    "nonEmptyGuid": "@:validations.required",
    "customer-number-already-in-use": "Customer number is already in use",
    "start-number-must-be-greater-than-last-used": "Start number must be greater than last used customer number"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "created": "Created",
    "add": "Add",
    "save":"Save",
    "update": "Update",
    "cancel": "Cancel",
    "delete": "Delete",
    "close": "Close",
    "create": "Create",
    "reload": "Reload",
    "column-chooser": "Column chooser",
    "reset": "Reset columns",
    "addresses": "Addresses",
    "name": "Name",
    "description": "Description",
    "phone-label": "Phone number",
    "phone-placeholder": "Type phone number",
    "loading-failed": "Failed to load customer, reason :",
    "current-page-template": "Showing {first} to {last} of {totalRecords} customers",
    "error": "An error occured",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text":"You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard":"Discard",
    "delete-confirm": "Do you want to delete this item?",
    "save-tooltip": "No changes",
    "validation-error": {
      "summary": "Validation failed",
      "detail": "Correct all red fields and try again"
    },
    "document-language": {
      "NO": "Norwegian",
      "DK": "Danish",
      "FI": "Finnish",
      "SE": "Swedish",
      "DE": "German",
      "IT": "Italian",
      "FR": "French",
      "EN": "English"
    },
    "address": {
      "address-lines": {
        "label": "Address lines",
        "placeholder": "Type in address lines, seperate with enter",
        "error": "At least one line is required"
      },
      "postal-code-city": "Postal code/city",
      "postal-code": "Postal code",
      "city": "City",
      "country": "Country"
    },
    "state": {
      "active": "Active",
      "inactive": "Inactive",
      "blocked": "Blocked"
    },
    "validation": {
      "email-exist": "Email already exist",
      "future-end-date": "End date needs to be set in the future"
    },
    "error-detail": {
      "unknown": "Unknown error",
      "server-error": "Server error",
      "bad-request": "Bad request"
    }
  },
  "customer": {
    "add-customer":"Add Customer",
    "edit-customer":"Edit Customer",
    "search-customer":"Search Customer",
    "customer-settings":"Customer Settings",
    "first-name": "First name",
    "last-name": "Last name",
    "confirm-delete": "Are you sure you want to delete this customer?",
    "created-message": "New customer created: ",
    "created-date":"Registered",
    "currency": "Currency",

    "customer-status": "Customer status",
    "document-language": "Document language",
    "organisation-number": "Organisation number",
    "company-name": "Company name",
    "email": "E-mail",
    "conditions": "Conditions",
    "freight": "Freight",
    "credit-limit": "Credit limit",
    "payment-term": "Payment term",
    "agreement-price": "Price",
    "start-date": "Start date",
    "end-date": "End date",
    "freight-method": "Standard freight method",
    "load-error": "Failed to load customer",
    "reload-customer": "Reloading customer, please wait...",
    "customer-type": "Customer type",
    "terms-and-agreements": "Terms and agreements",
    "business-customer": "Business customer",
    "customer-number": {
      "label": "Customer number",
      "placeholder": "Auto assign, type to override"
    },
    "import": {
      "label": "Import customers",
      "browse": "Browse",
      "number-of-columns-error": "Number of columns doesnt match with expected {customerType} columns",
      "invalid-columns-error": "Invalid column in csv file: {templateColumn} Expected: {expectedColumn}"
    },
    "type":{
      "b2b": "Business ",
      "b2c":"Private "
    },
    "toast": {
      "import": {
        "success": "Customers imported successfully!",
        "error": "Customer import failed!"
      },
      "edit": {
        "success-summary": "Customer successfully updated!",
        "success-detail": "Now redirecting to customer list",
        "error-summary": "Update failed",
        "error-detail": "Could not update customer"
      },
      "delete": {
        "success-summary": "Customer deleted",
        "error-summary": "Could not delete customer"
      },
      "open-customer-failed": "Failed to open customer",
      "no-customer-selected": "No customer selected. Select a customer and try again."
    },
    "contact-list": {
      "label": "Contacts",
      "first-name": "First name",
      "last-name": "Last name",
      "email": "E-mail",
      "phone-number": "Phone number",
      "invoice-recipient": "Invoice",
      "invoice-tooltip": "Select if you want contact to have invoice and credit note by email instead",
      "not-found": "No contacts found",
      "delete-confirm": "Are you sure you want to delete this contact?"
    },
    "freight-agreements": {
      "freight-method": "Freight method",
      "label": "Freight agreements",
      "tooltip": "Set one or more freight agreements for this customer",
      "fixed-freight": "Fixed freight",
      "no-agreements-found": "No freight agreements found for this customer",
      "delete-confirm": "Are you sure you want to delete this freight agreement?"
    },
    "address": {
      "invoice": "Invoice address",
      "delivery": "Delivery address"
    },
    "customer-group": {
      "name": "Name",
      "description": "Description",
      "customer-groups": "Customer groups",
      "not-found": "No customer groups found",
      "server-error": "Failed to load customer groups",
      "label": "Customer group",
      "select": "Select a customer group...",
      "search": "Search for a customer group...",
      "loading": "Loading customer groups...",
      "delete-confirm": "Are you sure you want to delete this customer group { groupName }?",
      "success-create": {
        "summary": "Customer group sucessfully added!",
        "detail": "Now adding customer group to customer.."
      },
      "success-update": {
        "summary": "Customer group sucessfully updated!",
        "detail": "For customer group {name}"
      },
      "success-delete": {
        "summary": "Customer group sucessfully deleted!",
        "detail": "For customer group {name}"
      }
    },
    "header": {
      "add-customer": "Add customer",
      "edit-customer": "Edit customer"
    },
    "search-header": {
      "add-customer": "Add customer",
      "customers": "Customer",
      "reset-filter": "Reset filters"
    },
    "search": {
      "total-hits": "{totalHits} customers found",
      "delete-selected": "Delete selected",
      "header-registered-date":"Registered",
      "header-number": "Customer number",
      "header-name": "Name",
      "header-email": "E-mail",
      "header-phone": "Phone",
      "header-delivery-address": "Delivery address",
      "header-delivery-city": "City",
      "header-address": "Invoice address",
      "header-city": "Invoice city",
      "header-country":"Country",
      "header-business-customer": "Business",
      "header-contact-name": "Contact name",
      "header-contact-email": "Contact email",
      "header-contact-phone": "Contact phone",
      "header-groups": "Customer groups",
      "search-error": "Search failed",
      "empty-list": "No customers Found.",
      "loading-list": "Loading customers, please wait...",
      "filter": "Filter",
      "filter-date-from": "From{symbol}",
      "filter-date-to": "To{symbol}",
      "filter-customer-registered-date": "Registered date",
      "filter-customer-type": "Customer type",
      "filter-customer-group":"Customer group",
      "show-filter": "Show filter",
      "hide-filter": "Hide filter",
      "clear-filter": "Clear filter"
    },
    "panel":{
      "close-panels": "Close tabs",
      "open-panels":"Open tabs"
    }
  }
}
