<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <Header
    :editMode="true"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :collapsedPanels="allPanelsCollapsed"
    @onDelete="onDeleteCustomer"
    @onCommit="onSave"
    @onCancel="onCancel"
    @stayOnPage="stayOnPage"
    @routeToCustomerList="routeToCustomerList"
    @onToggleAllPanels="($event) => onToggleAllPanels($event)"
  />
  <div v-if="!loadFailed && !isLoading" class="c-edit-customer">
    <div class="c-content" :class="{ 'mb-6 ': !panels.get('addressPanelCollapsed') }">
      <div class="c-content-top">
        <div class="c-content-top-left">
          <div class="c-card flex flex-wrap mr-4">
            <div class="w-full mr-5">
              <div class="c-created flex justify-content-end">
                <span class="c-created-text">{{ t("common.created") }} : </span>
                <span class="c-created-date" data-testid="registered-date">{{ registeredDateComputed }}</span>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <FirstName
                v-if="!isBusinessCustomer"
                v-model:firstName="customer.privateCustomer.firstName"
                :focusInput="true"
              />
              <CompanyName
                v-if="isBusinessCustomer"
                v-model:companyName="customer.businessCustomer.companyName"
                :focusInput="true"
              />
            </div>
            <div class="field col-12 md:col-6">
              <OrganizationNumber
                v-if="isBusinessCustomer"
                v-model:organizationNumber="customer.businessCustomer.organizationNumber"
              />
              <LastName v-if="!isBusinessCustomer" v-model:lastName="customer.privateCustomer.lastName" />
            </div>
            <div class="field col-12 md:col-6">
              <CustomerEmail v-model:emailAddress="customer.email" />
            </div>
            <div class="field col-12 md:col-6">
              <PhoneInput id="customer-phone" v-model="customer.phoneNumber" />
            </div>

            <div class="field col-12 md:col-6 mb-0">
              <CustomerNumber v-model:customerNumber="customer.customerNumber" />
            </div>
          </div>
        </div>
        <div class="c-content-top-right">
          <div class="c-card flex flex-wrap">
            <div class="c-col-1 px-2 -mx-3 mb-4">
              <div class="mt-3 mb-4">
                <CustomerType v-model:customerType="customer.customerType" />
              </div>

              <div class="mb-4">
                <CustomerState v-model:customerState="customer.customerState" />
              </div>

              <div class="mb-2">
                <DocumentLanguage v-model:documentLanguage="customer.documentLanguageIso" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid mx-1" :class="{ 'mb-6 ': !panels.get('addressPanelCollapsed') }">
      <div class="flex col-12">
        <CumulusPanel
          toggleTestId="c-addresses-panel"
          :collapsed="panels.get('addressPanelCollapsed')"
          @onCollapsedChange="panels.set('addressPanelCollapsed', $event)"
        >
          <template #title> {{ t("common.addresses") }} </template>
          <template #content>
            <div class="formgrid grid flex-column lg:flex-row -mt-3">
              <div class="w-full flex-1 c-border-div pr-3">
                <Address
                  :selectedCountry="customer.deliveryAddress.countryIso"
                  :address="customer.deliveryAddress"
                  :countryOptions="countryOptions"
                  @setAddress="setDeliveryAddress"
                  @autoSelectCountry="autoSelectedCountry"
                  :addressType="addressTypeDelivery"
                  :addressLabel="t('customer.address.delivery')"
                />
              </div>
              <div class="w-full flex-1 pl-3 c-border-div">
                <Address
                  :selectedCountry="customer.address.countryIso"
                  :address="customer.address"
                  :countryOptions="countryOptions"
                  @setAddress="setCompanyAddress"
                  @autoSelectCountry="autoSelectedCountry"
                  :addressType="addressTypeInvoice"
                  :addressLabel="t('customer.address.invoice')"
                />
              </div>
            </div>
          </template>
        </CumulusPanel>
      </div>
    </div>

    <div class="grid mx-1" :class="{ 'mb-6 ': !panels.get('termsAndAgreementsPanelCollapsed') }">
      <div class="flex col-12">
        <CumulusPanel
          toggleTestId="c-terms-and-agreements-panel"
          :collapsed="panels.get('termsAndAgreementsPanelCollapsed')"
          @onCollapsedChange="panels.set('termsAndAgreementsPanelCollapsed', $event)"
        >
          <template #title> {{ t("customer.terms-and-agreements") }} </template>
          <template #content>
            <div class="grid -mt-4">
              <div class="col-12 md:col-9 c-border-div">
                <div class="grid">
                  <div class="field col-12 md:col-3">
                    <DefaultFreightMethod
                      v-model:defaultFreightMethod="customer.freight.defaultFreightMethodId"
                      :freightMethods="freightMethods"
                    />
                  </div>
                  <div class="field col-12 md:col-3">
                    <DefaultPaymentTerm v-model:paymentTermId="customer.payment.defaultPaymentTermId" />
                  </div>
                  <div class="field col-12 md:col-3">
                    <CreditLimit v-model:creditLimit="customer.payment.creditLimit" />
                  </div>
                  <div class="field col-12 md:col-3">
                    <Currency v-model:currency="customer.payment.currencyIso" />
                  </div>
                  <div class="field col-12 md:col-12">
                    <FreightAgreements
                      v-model:freightAgreements="customer.freight.freightAgreements"
                      :freightMethods="freightMethods"
                    />
                  </div>
                </div>
              </div>
              <div class="field col-12 md:col-3">
                <CustomerGroup v-model:customerGroups="customer.customerGroupIds" />
              </div>
            </div>
          </template>
        </CumulusPanel>
      </div>
    </div>

    <div class="grid mx-1" :class="{ 'mb-6 ': !panels.get('contactsPanelCollapsed') }" v-if="isBusinessCustomer">
      <div class="flex col-12">
        <CumulusPanel
          toggleTestId="c-contacts-panel"
          :collapsed="panels.get('contactsPanelCollapsed')"
          @onCollapsedChange="onCollapsedChange"
        >
          <template #title> {{ t("customer.contact-list.label") }} </template>
          <template #content>
            <ContactList v-model:contacts="customer.businessCustomer.contacts" />
          </template>
        </CumulusPanel>
      </div>
    </div>
  </div>

  <div v-if="loadFailed">
    <div class="c-card">{{ t("common.loading-failed") }} {{ errorReason }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import CustomerNumber from "../components/CustomerNumber.vue";
import OrganizationNumber from "../components/OrganizationNumber.vue";
import CustomerType from "../components/CustomerType.vue";
import Address from "../components/Address.vue";
import CreditLimit from "../components/CreditLimit.vue";
import Currency from "../components/Currency.vue";
import CustomerState from "../components/CustomerState.vue";
import DefaultPaymentTerm from "../components/DefaultPaymentTerm.vue";
import CustomerGroup from "../components/customer-group/CustomerGroup.vue";
import DefaultFreightMethod from "../components/DefaultFreightMethod.vue";
import FreightAgreements from "../components/FreightAgreements.vue";
import FirstName from "../components/FirstName.vue";
import LastName from "../components/LastName.vue";
import CompanyName from "../components/CompanyName.vue";
import CustomerEmail from "../components/CustomerEmail.vue";
import PhoneInput from "../components/PhoneInput.vue";
import ContactList from "../components/ContactList.vue";
import Header from "../components/Header.vue";
import DocumentLanguage from "../components/DocumentLanguage.vue";
import { CumulusPanel } from "@cumulus/panel";
import { customerAddressFunctions } from "../hooks/CustomerAddressFunction";

import { CustomerType as CustomerTypeModel } from "@/models/customer/CustomerType";
import { Country } from "@/models/country/Country";
import { Customer } from "@/models/customer/Customer";
import { useCustomer } from "@/api/customer/CustomerService";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useCountry } from "@/api/country/CountryService";
import { useFreightMethod } from "@/repositories/freight-method/FreightMethodService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const { getCustomer, updateCustomer, deleteCustomer } = useCustomer();
const { getAllCountries } = useCountry();
const { getAllFreightMethods } = useFreightMethod();

const { t, d } = useI18n();
const toast = useCumulusToast(useToast());
const route = useRoute();
const router = useRouter();
const customerId = ref("");
const countryOptions = ref<Country[]>([]);
const confirmedDiscard = ref(false);

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const routeToCustomerList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/customer/search") === -1) {
    router.push({ name: "customer-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", customer.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const loadFailed = ref(false);
const isLoading = ref(true);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const errorReason = ref("");
const customer = ref(new Customer());
const initialCustomer = ref<Customer | null>(null);
const addressTypeDelivery = ref("delivery");
const addressTypeInvoice = ref("invoice");
const { setCompanyAddress, setDeliveryAddress } = customerAddressFunctions(customer);
//Nested validation
const val = useValidate();

const panels = ref(
  new Map<string, boolean>([
    ["addressPanelCollapsed", true],
    ["termsAndAgreementsPanelCollapsed", true],
    ["contactsPanelCollapsed", true],
  ])
);

const onCollapsedChange = ($event: boolean) => {
  panels.value.set("contactsPanelCollapsed", $event);
};

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const fetchCustomerData = async () => {
  customerId.value = route.params.id as string;
  isLoading.value = true;
  loadFailed.value = false;

  try {
    customer.value = await getCustomer(customerId.value);
    initialCustomer.value = cloneDeep(customer.value);
  } catch {
    loadFailed.value = true;
  } finally {
    isLoading.value = false;
  }
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onToggleAllPanels = (currentValue: boolean) => {
  panels.value.forEach((_, key) => panels.value.set(key, !currentValue));
};

const allPanelsCollapsed = computed(() => {
  return Array.from(panels.value.values()).every((panelCollapsed) => panelCollapsed === true);
});

const onSave = async () => {
  unsavedChangesDialogVisible.value = false;
  //Wait for onblur functions
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await updateCustomer(customer.value);

  toast.add({
    severity: "success",
    summary: t("customer.toast.edit.success-summary"),
    detail: t("customer.toast.edit.success-detail"),
    closable: true,
  });

  routeToCustomerList();
};

const onDeleteCustomer = async () => {
  await deleteCustomer(customer.value.id);

  toast.add({
    severity: "success",
    summary: t("customer.toast.delete.success-summary"),
    closable: true,
  });

  routeToCustomerList();
};

const isBusinessCustomer = computed(() => {
  return customer.value.customerType === CustomerTypeModel.B2B;
});

const autoSelectedCountry = (countryIso: string) => {
  if (customer.value.deliveryAddress.countryIso === "") {
    customer.value.deliveryAddress.countryIso = countryIso;
    customer.value.address.countryIso = countryIso;
  }
};

const freightMethods = ref<FreightMethod[]>([]);
const fetchFreightMethods = async () => {
  freightMethods.value = await getAllFreightMethods();
};
const registeredDateComputed = computed(() => {
  return customer.value.registeredDate ? d(customer.value.registeredDate, "short") : "";
});

const hasUpdates = computed(() => {
  return !isEqual(customer.value, initialCustomer.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToCustomerList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([fetchCustomerData(), fetchCountryData(), fetchFreightMethods()]);
});
const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToCustomerList();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

useShortcut(ShortcutAction.save, onSave);
window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});
</script>

<style scoped lang="scss">
.c-edit-customer {
  margin: var(--default-content-margin);
  margin-bottom: 7rem;
}

.c-border-div {
  padding: 0.5rem;
  border-right: var(--footer-border);

  @media screen and (max-width: 992px) {
    border-right: none;
  }
}

.c-border-div:last-child {
  border-right: none;
}
.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 75%;
}
.c-content-top-right {
  flex: 25%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-purchase-order-lines-card {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.c-card {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}
</style>
