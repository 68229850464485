<template>
  <ProgressSpinner v-if="saving" />
  <div v-if="!saving">
    <div class="field">
      <label for="customer-group-name">
        {{ t("customer.customer-group.name") }}
      </label>
      <InputText
        ref="customerGroupNameRef"
        type="text"
        class="inputfield w-full"
        maxlength="500"
        v-model="customerGroupRef.name"
        :class="{ 'p-invalid': val.name.$error }"
        :placeholder="t('placeholder.type', { property: t('customer.customer-group.name').toLowerCase() })"
        data-testid="customer-group-name"
        @focus="selectAllOnFocus"
        @keydown.esc.stop="$emit('closeForm')"
      />
      <small
        id="customer-group-name-help"
        class="p-error"
        data-testid="customer-group-name-error"
        v-if="val.name.$error"
      >
        {{ val.name.$errors[0].$message }}
      </small>
    </div>

    <div class="field">
      <label for="customer-group-description">
        {{ t("customer.customer-group.description") }}
      </label>
      <InputText
        type="text"
        class="inputfield w-full"
        maxlength="500"
        v-model="customerGroupRef.description"
        :class="{ 'p-invalid': val.description.$error }"
        :placeholder="t('placeholder.type', { property: t('customer.customer-group.description').toLowerCase() })"
        data-testid="customer-group-description"
        @focus="selectAllOnFocus"
        @keydown.esc.stop="$emit('closeForm')"
      />
      <small
        id="customer-group-description-help"
        class="p-error"
        data-testid="customer-group-description-error"
        v-if="val.description.$error"
      >
        {{ val.description.$errors[0].$message }}
      </small>
    </div>
  </div>

  <div class="w-full">
    <div class="flex justify-content-between">
      <PrimeButton
        type="button"
        :label="t('common.cancel')"
        class="p-button-warning"
        @click="$emit('closeForm')"
        tabindex="1"
      ></PrimeButton>

      <PrimeButton
        v-if="editMode"
        type="button"
        :label="t('common.delete')"
        class="p-button-secondary"
        @click="confirmDeleteCustomerGroup"
        data-testid="btn-cg-delete"
        :disabled="saving"
      ></PrimeButton>
      <ConfirmPopup data-testid="customer-group-delete-confirm"></ConfirmPopup>

      <PrimeButton
        v-if="editMode"
        type="button"
        :label="t('common.update')"
        class="p-button-success"
        @click="onCommit"
        data-testid="btn-cg-update"
        :disabled="saving"
      ></PrimeButton>

      <PrimeButton
        v-if="!editMode"
        type="button"
        :label="t('common.add')"
        class="p-button-success"
        @click="onCommit"
        data-testid="btn-cg-add"
        :disabled="saving"
      ></PrimeButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useVuelidate } from "@vuelidate/core";
import { useConfirm } from "primevue/useconfirm";
import { NIL as emptyUuid } from "uuid";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { useCustomerGroupStore } from "@/repositories/customer-group/CustomerGroupStore";
import { required } from "@/locales/i18n-validators";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuidv4 } from "uuid";

const customerGroupStore = useCustomerGroupStore();
const { saving } = storeToRefs(customerGroupStore);

const props = defineProps<{
  customerGroup: CustomerGroup;
}>();

const emit = defineEmits<{
  (e: "closeForm"): void;
}>();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();
const customerGroupNameRef = ref();
const customerGroupRef = ref<CustomerGroup>(new CustomerGroup());
const editMode = ref(false);

nextTick(() => {
  customerGroupNameRef.value.$el.focus();
});

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

onMounted(() => {
  customerGroupRef.value = cloneDeep(props.customerGroup);
  if (props.customerGroup.id !== emptyUuid) editMode.value = true;
});

const onCommit = async () => {
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  if (!editMode.value) {
    const cg: CustomerGroup = {
      id: uuidv4(),
      name: customerGroupRef.value.name,
      description: customerGroupRef.value.description,
    };

    await customerGroupStore.createCustomerGroup(cg);
    toast.add({
      severity: "success",
      summary: t("customer.customer-group.success-create.summary"),
      detail: t("customer.customer-group.success-create.detail"),
      closable: true,
    });
  } else if (editMode.value) {
    await customerGroupStore.updateCustomerGroup(customerGroupRef.value);
    toast.add({
      severity: "success",
      summary: t("customer.customer-group.success-update.summary"),
      detail: t("customer.customer-group.success-update.detail", { name: customerGroupRef.value.name }),
      closable: true,
    });
  }

  emit("closeForm");
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const confirmDeleteCustomerGroup = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("customer.customer-group.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      acceptDeleteCustomerGroup();
    },
  });
};

const acceptDeleteCustomerGroup = async () => {
  await customerGroupStore.deleteCustomerGroup(customerGroupRef.value);
  toast.add({
    severity: "success",
    summary: t("customer.customer-group.success-delete.summary"),
    detail: t("customer.customer-group.success-delete.detail", { name: customerGroupRef.value.name }),
    closable: true,
  });
  emit("closeForm");
};

const rules = {
  name: {
    required,
  },
  description: {
    required,
  },
};

const val = useVuelidate(rules, customerGroupRef);
</script>
